@import "../src/Common/all.scss";
@import "bootstrap/dist/css/bootstrap.min.css";
:root {
  // TYPOGRAPHY
  --ff-body: "Saira", sans-serif;

  --fs-h1: 2rem;
  --fs-h2: 2.4rem;
  --fs-h3: 1.6rem;
  --fs-btn: 1.6rem;
  --fs-p: 1.4rem;

  //SPACING
  --section-p: 6.4rem;
  --header-margin: 3.2rem;
}
@include media-min($md) {
  :root {
    // TYPOGRAPHY

    --fs-h2: 4rem;
    --fs-p: 1.6rem;

    //SPACING
    --section-p: 10rem;
    --header-margin: 4rem;
  }
}
@include media-min($lg) {
  :root {
    // TYPOGRAPHY
    --fs-h1: 2.4rem;
    --fs-h2: 4.8rem;

    --fs-btn: 2.2rem;

    //SPACING
    --section-p: 15rem;
    --header-margin: 5.6rem;
  }
}
@include media-min($xl) {
  :root {
    // TYPOGRAPHY
    --fs-h2: 6rem;
    --fs-h3: 1.8rem;
    --fs-btn: 2.4rem;

    //SPACING
    --section-p: 20rem;
    --header-margin: 6.4rem;
  }
}

html {
  box-sizing: border-box;
  font-size: 10px;
  // scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden;
  font-family: var(--ff-body);
  background-color: $bg;
  background-image: url(../public/bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.body.termsOpened {
  overflow: hidden;
}

h1 {
  font-size: var(--fs-h1);
  font-weight: 500;
  letter-spacing: 0.3em;
  color: $high-emphasis-white;
  text-shadow: 0px 4px 8px rgba(255, 255, 255, 0.8);
}

h2 {
  font-size: var(--fs-h2);
  color: $color-primary;
  font-weight: normal;
  letter-spacing: 0.15em;
  text-align: center;
  margin-bottom: var(--header-margin);
}

h3 {
  font-size: var(--fs-h3);
  color: $high-emphasis-white;
  font-weight: normal;
  letter-spacing: 0.15em;
}

p {
  font-size: var(--fs-p);
  color: $p-white;
  font-weight: 400;
}

.btn-primary,
.button-transparent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 1.2rem 5rem;
  outline: none;
  border: none;
  letter-spacing: 0.1em;
  background: linear-gradient(144.08deg, #fb40c5 -23.22%, #651fff 46.22%);
  /* Basic [M] */

  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  font-size: var(--fs-btn);
  font-weight: 500;
  color: $high-emphasis-white;
}
.button-transparent {
  background: transparent;
  box-shadow: 0px 0px 24px #00000040, inset 1px 1px 2px #ffffff61,
    inset -1px -1px 2px #ffffff61;
  color: $medium-emphasis-white;
}
// .container {
//   padding-left: 2.4rem;
//   padding-right: 2.4rem;
//   max-width: 100%;
//   @media only screen and (min-width: 576px) {
//     padding-left: 5.6rem;
//     padding-right: 5.6rem;
//   }

//   @media only screen and (min-width: 768px) {
//     padding-left: 0;
//     padding-right: 0;
//     max-width: 720px;
//   }
//   @media only screen and (min-width: 991px) {
//     max-width: 960px;
//   }
//   @media only screen and (min-width: 1200px) {
//     max-width: 1140px;
//   }
//   @media only screen and (min-width: 1400px) {
//     max-width: 1320px;
//   }
// }
// header,
// section {
//   padding-left: 1.6rem;
//   padding-right: 1.6rem;
// }

section {
  padding: var(--section-p) 1.6rem;
  border-bottom: 1px solid $low-emphasis-white;
}
footer {
  padding: var(--section-p) 1.6rem;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.8rem 1.6rem;
  @include media-min($xl) {
    padding: 0.8rem 2.4rem;
  }
  .logo-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo1 {
      img {
        width: 6rem;
        @include media-min($md) {
          width: 8rem;
        }
      }
    }
    .logo2 {
      img {
        width: 5rem;
        @include media-min($md) {
          width: 8rem;
        }
      }
    }
  }
}

.hero {
  position: relative;
  background-image: url(../public/hero-bg-mobile.png);
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position-x: 50%;
  background-repeat: no-repeat;
  @include media-min($md) {
    background-image: url(../public/hero-bg.png);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.8rem;
      text-transform: uppercase;
      @include media-min($md) {
        flex-direction: row;
      }
      span.seperator {
        display: block;
        width: 0.8em;
        height: 0.15em;
        background-color: $high-emphasis-white;
        @include media-min($md) {
          width: 0.15rem;
          height: 0.7em;
        }
      }
    }
    a {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.welcome {
  .container {
    > p {
      text-align: center;
    }
    .grid {
      margin-bottom: 5.5rem;
      display: grid;
      grid-template-columns: 1fr;
      gap: 5.6rem;
      padding: 0 1.4rem;
      @include media-min($md) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2.4rem;
        margin-bottom: 6.4rem;
        padding: 0;
      }
      .wcard {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        @include media-min($xl) {
          flex-direction: column-reverse;
        }
        .wcard-header {
          display: flex;
          align-items: center;
          gap: 1.6rem;

          span {
            font-weight: 600;
            font-size: 36px;
            color: $color-primary;
          }

          h3 {
            text-transform: uppercase;
          }
        }
        .wcard-body {
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease-in;
          img {
            width: 100%;
            margin-bottom: 0.8rem;
            transition: all 0.3s ease-in;
            @include media-min($xl) {
              margin-bottom: 0;
              transform: scale(1.1) translateY(0);
            }
          }
          .body-content {
            padding: 0 0.4rem;
            transition: all 0.3s ease-in;

            p {
              // @include media-min($xl) {
              //   position: absolute;
              //   width: 100%;
              // }
            }
            @include media-min($xl) {
              position: absolute;
              background-color: #b388ff;
              bottom: -30%;
              transition: all 0.3s ease-in;
              opacity: 0;
              padding: 1rem 1rem;
            }
          }
        }
        .wcard-body:hover > img {
          @include media-min($xl) {
            transform: scale(1) translateY(-10%);
          }
        }
        .wcard-body:hover .body-content {
          @include media-min($xl) {
            top: unset;
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }
  }
}

.contacts {
  form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    align-items: center;
    max-width: 800px;
    margin: auto;
  }
  button {
    margin-top: 4rem;
  }
  input:not([type="checkbox"]) {
    height: 42px;
  }

  input,
  select {
    background: #ffffff;
    box-shadow: -2px -2px 8px #606060, 2px 2px 8px #424242,
      inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 21px;

    width: 100%;
  }
  input,
  select {
    padding: 0.8rem 1.4rem;
    font-size: 16px;
    border: 1px solid #d6d1d5;
  }
  select {
    display: block;
    width: 100%;
    height: 42px;
  }
  input[type="checkbox"] {
    display: inline-block;
    background: #eceff1;
    border-radius: 4px;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: 0.3rem;
    box-shadow: none;
  }
  input.hidden {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 2em;
  }
  .phone-wrapper {
    width: 100%;
    position: relative;
  }
  .label {
    display: flex;
    align-items: flex-start;
    font-size: var(--fs-p);

    gap: 1.2rem;
    padding: 0 1.2rem;
    p {
      color: $high-emphasis-white;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.react-tel-input {
  input {
    width: 100% !important;
    border-radius: 10rem !important;
  }
  .flag-dropdown {
    border-top-left-radius: 10rem !important;
    border-bottom-left-radius: 10rem !important;
  }
  .selected-flag {
    border-top-left-radius: 10rem !important;
    border-bottom-left-radius: 10rem !important;
  }
}

footer {
  //   background-image: url(https://espl.gg/assets/circle-pattern.png);
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position-y: bottom;
  background-color: transparent;
  .container-md {
    h2 {
      text-align: left;
      color: $high-emphasis-white;
    }
    h4 {
      font-weight: 300;
      font-size: 32px;
      line-height: 38px;
      color: $high-emphasis-white;
      margin-bottom: 4rem;
      @media only screen and (max-width: 1200px) {
        font-size: 28px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .contact-options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5rem;
      margin-bottom: 4rem;
      a {
        .contact-option {
          border-radius: 1rem;
          padding: 4.8rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
          color: $high-emphasis-white;
          transition: all 0.3s ease-in;
          @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2.4rem;
          }
          .button-footer {
            outline: none;
            border: none;
            padding: 1.6rem 3.2rem;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
            border-radius: 3.5rem;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            color: $high-emphasis-white;
            transition: all 0.2s ease-in;
            cursor: pointer;
            background-color: #651fff;
            text-align: center;

            @media only screen and (max-width: 768px) {
              font-size: 18px;
              line-height: 28px;
            }
            @media only screen and (max-width: 600px) {
              width: 100%;
            }
          }
          .contact-option-name {
            display: flex;
            align-items: center;
            gap: 2.4rem;
            svg {
              width: 48px;
              height: 48px;
            }
            p {
              font-weight: normal;
              font-size: 24px;
              line-height: 28px;
              color: $high-emphasis-white;
              margin: 0;
              cursor: pointer;
            }
          }
        }
        .email-us {
          background-color: #651fff;
          .button-footer {
            background-color: #651fff;
            text-align: center;
          }
        }
        .discord-chat {
          background-color: #5865f2;
          .button-footer {
            background-color: #5865f2;
            text-align: center;
          }
        }
        &:hover {
          text-decoration: none;

          .contact-option {
            filter: brightness(90%);
          }
        }
      }

      @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        gap: 3rem;
      }
    }
    .footer-primary-links {
      display: grid;
      grid-template-columns: repeat(4, auto);
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4rem;
      @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(3, auto);
        justify-content: unset;
        row-gap: 4rem;
        .social-media-col {
          grid-column: 2/3;
          justify-self: center;
        }
        .link-col-2 {
          justify-self: center;
        }
        .link-col-3 {
          justify-self: end;
        }
      }

      @media only screen and (max-width: 768px) {
        grid-template-columns: auto;
        justify-content: center;
        .social-media-col {
          grid-column: unset;
        }
        justify-items: center;
        .link-col-3 {
          justify-self: unset;
        }
      }
      .primary-link-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: flex-start;
        p {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: $high-emphasis-white;
          margin: 0;
        }
        a {
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          text-decoration-line: underline;
          color: $high-emphasis-white;
        }
      }
      .social-media-col {
        display: flex;

        align-items: center;
        a {
          svg {
            width: 24px;
          }
          margin-right: 3.2rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .twitter {
        svg {
          width: 27px !important;
        }
      }
    }
    .footer-secondary-links {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 2.4rem;
        align-items: center;
      }
      .secondary-links {
        display: flex;
        gap: 4rem;
        @media only screen and (max-width: 1200px) {
          gap: 2rem;
        }
        a {
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          color: $high-emphasis-white;
        }
        @media only screen and (max-width: 600px) {
          flex-direction: column;
          align-items: center;
          gap: 0.8rem;
        }
      }
      h5 {
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: $high-emphasis-white;
      }
    }
  }
}

.modal-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  .terms-modal {
    padding: 5.6rem 3.6rem;
    width: clamp(34rem, 45vw, 90rem);
    background: linear-gradient(121.38deg, #424242 0%, #121212 100%);
    border-radius: 40px;

    @include media-min($xl) {
      padding: 6.4rem 4.8rem;
    }
    .modal-header {
      border-bottom: none !important;
      justify-content: center !important;
      text-align: center;
      padding: 0;

      margin-bottom: 2.4rem;
      .modal-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.9);
      }
    }
    .terms-modal-footer {
      border: none !important;
      justify-content: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 3.6rem;
      gap: 1.6rem;
      button {
        width: 100%;
        padding: 0.8rem 1rem;
        font-size: 1.6rem;
      }
    }
    .modal-body {
      text-align: center;
      max-height: 344px;
      overflow-y: scroll;

      h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.6rem;

        /* /Header */
        text-align: left;
        color: rgba(255, 255, 255, 0.9);
      }
      hr {
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.51);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(66, 66, 66, 1);
        border-radius: 20px;
        border: 2px solid rgba(255, 255, 255, 0.51);
      }
    }
    .modal-backdrop {
      background-color: transparent !important;
    }
  }
}
.smodal-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  .terms-modal {
    padding: 5.6rem 3.6rem;
    width: clamp(34rem, 45vw, 90rem);
    background: linear-gradient(121.38deg, #424242 0%, #121212 100%);
    border-radius: 40px;

    @include media-min($xl) {
      padding: 6.4rem 4.8rem;
    }
    .modal-header {
      margin-bottom: 0 !important;
      border: 0;
      justify-content: center;
      h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 3.2rem;
        margin-bottom: 0;
        /* /Header */
        text-align: center !important;
        color: #25ced1;
        @include media-min($lg) {
          font-size: 4rem;
        }
      }
    }
    .modal-body {
      overflow-y: unset !important;

      h3 {
        text-align: center;
      }
    }
    .terms-modal-footer {
      display: flex;
      justify-content: center;
    }
  }
}
.errMessage {
  color: #f8bbd0;
}

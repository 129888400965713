$xs: "375px";
$sm: "576px";
$md: "768px";
$lg: "992px";
$xl: "1200px";
$xxl: "1400px";

@mixin media-min($size) {
  @media (min-width: $size) {
    @content;
  }
}
@mixin media-max($size) {
  @media (max-width: $size) {
    @content;
  }
}
